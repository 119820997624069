<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import InputTag from 'vue-input-tag';
import { VueEditor } from "vue2-editor";
//import Basic from "./basic";
import axios from 'axios';

import {
  required,
  email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Organization registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, InputTag, VueEditor /*Basic*/ },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title: "Organization registration",
      componentKey: 0,
      num_lat: 0.0,
      num_lng: 0.0,
      items: [
        {
          text: "Company",
          href: "/"
        },
        {
          text: "Sites",
          href: "/company/sites"
        },
        {
          text: "Site",
          active: true
        }
      ],
      form: {
        nm_company: "",
        de_companySlogan: "",
        de_companyShortAddress: "",
        de_companyAbout: "",
        de_companyContactEmail: "",
        num_companyContactPhone: "",
        de_companyTags:[],
        de_companyHistory: "",
        de_companyIndustry: "",
        de_companyCapabilities: "",
        url_facebook: "",
        url_twitter: "",
        url_instagram: "",
        url_linkedin:"",
        url_youtube:""
      },
      submitted: false,
      validToken: true,
    };
  },
  validations: {
    form: {
      nm_company: {required},
      de_companySlogan: { required },
      de_companyShortAddress: { required },
      de_companyAbout: { required, minLength: minLength(100), maxLength: maxLength(1024) },
      de_companyContactEmail: { required, email },
      num_companyContactPhone: { required }
    }
  },
  watch:{
    lat(newLat, oldLat){
      if(newLat != oldLat){
        this.lat = newLat;
        this.forceRerenderMap();
      }
    },
    lng(newLng, oldLng){
      if(newLng != oldLng){
        this.lng = newLng;
        this.forceRerenderMap();
      }
    },
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    if( this.$route.params.id ){
      console.log(this.$route.params.id);
      this.isEditState = true;
    }

    this.getOrganization();
  },
  methods: {
    getOrganization(){
      Swal.fire({
        title: "Loading Organization.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      let app = this;

      axios
      .get(
         window.serverPath+'dist/mvw/controllers/organizations.php',
        {
          params: {
            request: 'getOrganization',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.organization = response.data;

            this.form.nm_company                  = app.organization.nm_company;
            this.form.de_companySlogan            = app.organization.de_companySlogan;
            this.form.de_companyShortAddress      = app.organization.de_companyShortAddress;
            this.form.de_companyAbout             = app.organization.de_companyAbout;
            this.form.de_companyContactEmail      = app.organization.de_companyContactEmail;
            this.form.de_companyHistory           = app.organization.de_companyHistory;
            this.form.de_companyIndustry          = app.organization.de_companyIndustry;
            this.form.de_companyCapabilities      = app.organization.de_companyCapabilities;
            this.form.num_companyContactPhone     = app.organization.num_companyContactPhone;
            this.form.de_companyTags              = app.organization.de_companyTags;

            this.form.url_facebook                = app.organization.url_facebook;
            this.form.url_twitter                 = app.organization.url_twitter;
            this.form.url_instagram               = app.organization.url_instagram;
            this.form.url_linkedin                = app.organization.url_linkedin;
            this.form.url_youtube                 = app.organization.url_youtube;

            if( this.$route.params.id ){
              this.form.id_company = app.organization.id_company;
            }

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveOrganization(e){
      let app   = this;
      let array = [1,2,12];

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        Swal.fire({
          title: "Saving organization.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if( this.$route.params.id ){
          this.form.id_company = this.$route.params.id;
        }

        if( !array.includes( this.user.user.id_role ) ){
          Swal.fire("Privileges error!", "You do not have privileges to add/modify organizations.", "error");
        }else{
          axios.post( window.serverPath+'dist/mvw/controllers/organizations.php', {
            request: 'saveOrganization',
            data: this.form, 
            token: this.user.token
          })
          .then(function (response) {
            if( response.data == 'Invalid token' ){
              this.validToken = false;
            }else{
              let action = "";
              if( response.data === "OK" ){
                action = "saved";
              }
              if( response.data === "UPDATED" ){
                action = "updated";
              }

              Swal.fire("Good job!", "Your organization is correct "+action+"!", "success");
              app.$router.push({ name: 'organizations' });
            }
          })
          .catch(function (error) {
            Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
          });
        }
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <form class="needs-validation" @submit.prevent="saveOrganization">
      <div class="row ml-1 mb-2">
        <button type="submit" class="btn btn-sm btn-success"><i class="ri-save-fill"></i> Save Organization</button>
      </div>

      <!--
      <div class="row" v-if="!isEditState">
        <div class="col-lg-12">
          <div class="email-leftbar card">
            <img src="@/assets/images/evalmatters-logo.png"/>
            <p class="card-title-desc text-center m-0"><b>Eval with sense</b></p>
            <p class="card-title-desc text-center m-0"><small>Ensenada, B.C. Mexico</small></p>

            <div class="card"></div>

            <div class="mail-list mt-3">
              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>About</b></div>
              <p>THIS IS MY COMPANY, IT WAS CREATED BECAUSE OF THE NEED TO KEEP WELL FEED OF MY 3 KIDS</p>
              

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Contact Information</b></div>
              <p>
                <small><i class="far fa-envelope"></i> contact@mycompany.com.mx</small><br>
                <small><i class="fas fa-phone-alt"></i> +52 (646) 1114859</small><br><br>
                <small>Ensenada B.C., Mexico. Punta Banda Colony #373 Dept 3, 2nd floor.</small>
              </p>

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Specialists on</b></div>
              <span v-for="tag in form.de_companyTags" :vid-id="tag" :key="tag" class="badge ml-1 badge-success badge-pill">{{tag}}</span>
            </div>
          </div>

          <div class="card email-rightbar">
            <div class="card-body">
              <b-tabs content-class="p-3 text-muted">
                <b-tab active class="border-0">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">History</span>
                  </template>
                  <p v-html="form.de_companyHistory"></p>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Industry</span>
                  </template>
                  <p v-html="form.de_companyIndustry"></p>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-envelope"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Capabilities</span>
                  </template>
                  <p v-html="form.de_companyCapabilities"></p>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-cog"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Social Networks</span>
                  </template>
                  <p></p>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
      -->


      <div class="row">
        <div class="col-lg-12">
          <div class="email-leftbar card">
            <div class="form-group text-center m-1">
              <input
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.form.nm_company.$error }"
                name="nm_company"
                id="nm_company"
                v-model="form.nm_company"
                type="text"
                placeholder="Organization name"
              />
            </div>
            <div class="form-group text-center m-1">
              <input
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.form.de_companySlogan.$error }"
                name="de_companySlogan"
                id="de_companySlogan"
                v-model="form.de_companySlogan"
                type="text"
                placeholder="Organization slogan"
              />
            </div>
            <div class="form-group text-center m-1">
              <input
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.form.de_companyShortAddress.$error }"
                name="de_companyShortAddress"
                id="de_companyShortAddress"
                v-model="form.de_companyShortAddress"
                type="text"
                placeholder="Organization location"
              />
            </div>


            <div class="card"></div>

            <div class="mail-list mt-4">
              <div class="form-group mt-0">
                <label for="de_companyAbout">About</label>
                <textarea
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.de_companyAbout.$error }"
                  name="de_companyAbout"
                  id="de_companyAbout"
                  v-model="form.de_companyAbout"
                  rows="5"
                  value=""
                  placeholder="Organization description (short)"
                >
                </textarea>
              </div>

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Contact Information</b></div>
              <p>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.de_companyContactEmail.$error }"
                  name="de_companyContactEmail"
                  id="de_companyContactEmail"
                  v-model="form.de_companyContactEmail"
                  type="text"
                  placeholder="Contact email"
                />
                <br>
                <input
                  v-mask="'+(##) ###-###-####'"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.num_companyContactPhone.$error }"
                  name="num_companyContactPhone"
                  id="num_companyContactPhone"
                  v-model="form.num_companyContactPhone"
                  placeholder="Phone number"
                />
                <br>
              </p>

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Specialists on</b></div>
              <input-tag v-model="form.de_companyTags" :limit="10" placeholder="10 feats separated by comma."></input-tag>
            </div>
          </div>

          <div class="card email-rightbar">
            <div class="card-body">
              <b-tabs content-class="p-3 text-muted">
                <b-tab active class="border-0">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">History</span>
                  </template>
                  <div class="form-group">
                    <label >Enter your company description.</label>
                    <vue-editor id="de_companyHistory" v-model="form.de_companyHistory"></vue-editor>
                  </div>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Industry</span>
                  </template>
                  <label >Enter a full description about the industry of your company.</label>
                  <vue-editor id="de_companyIndustry" v-model="form.de_companyIndustry"></vue-editor>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-envelope"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Capabilities</span>
                  </template>
                  <label >Enter a full description about capabilities of your company.</label>
                  <vue-editor id="de_companyCapabilities" v-model="form.de_companyCapabilities"></vue-editor>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-cog"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Social Networks</span>
                  </template>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Facebook</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-facebook-f"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Facebook profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_facebook"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Twitter</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-twitter"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Twitter profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_twitter"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Instagram</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-instagram"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Instagram profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_instagram"
                          />
                        </div>
                      </div>

                      <!--
                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Whatsapp</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-whatsapp"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Whatsapp company number"
                            aria-describedby="validationTooltipUsernamePrepend"
                          />
                        </div>
                      </div>
                      -->

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">LinkedIn</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-linkedin-in"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="LinkedIn profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_linkedin"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Youtube</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-youtube"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Youtube profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_youtube"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </form>
  </Layout>
</template>

<style type="text/css">
  .vue-input-tag-wrapper .input-tag{
    color: #fff !important;
    background-color: #5664d2 !important;
    border-color: #5664d2 !important;

    display: inline-block !important;
    font-weight: 400 !important;
    text-align: center !important;
    vertical-align: middle !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    background-color: transparent;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.05rem 0.1rem !important;
    font-size: 0.7875rem !important;
    line-height: 1.5 !important;
    border-radius: 0.2rem !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff;
    display: inline-block;
  }

  .mail-list a {
    display: block;
    color: #74788d;
    /* line-height: 24px; */
    padding: 2px 3px;
  }
  .input-group-text{
    background-color: white;
  }
</style>